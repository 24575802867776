import React from "react"
import { Link, graphql } from "gatsby"
import LazyLoad from "react-lazyload"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Text from "../components/blocks/text"
import SEO from "../components/seo"
import Video from "../components/blocks/video"

import "sanitize.css/sanitize.css"
import "../styles/main.css"

import logo from "../images/logo.svg"

const IndexPage = ({ data }) => {
  const frontpage = data.datoCmsFrontpage
  const sessions = data.allDatoCmsSession.nodes
  const allFacilitators = data.allDatoCmsFacilitator.nodes
  const sortedFacilitators = [...allFacilitators].sort((a, b) => {
    if (!a.priority) return 1
    if (!b.priority) return -1
    return a.priority - b.priority
  })
  const sortedSessions = [...sessions].sort((a, b) => {
    if (!a.priority) return 1
    if (!b.priority) return -1
    return a.priority - b.priority
  })

  function buyTickets(e) {
    e.preventDefault()

    /* eslint-disable */
    if (typeof fbq !== "undefined") {
      fbq("trackCustom", "Get tickets")
    }
    /* eslint-enable */

    window.open(e.target.href, "_blank")
  }

  return (
    <Layout hasHero>
      <SEO
        title="PachAbuela Spirit Gathering | Jan. 7th - 10th, 2020 | Ometepe, Nicaragua"
        hideTemplate
      />

      <Hero size="normal" src={`${frontpage.mainImage.url}`}>
        <div class="pacha-info">
          <h1 class="pacha-info__title">
            Pach
            <img alt="Logo" src={logo}></img>
            buela
          </h1>

          <h2 class="pacha-info__subtitle">Spirit Gathering</h2>
          <p class="pacha-info__date">7 - 10. January 2020</p>
          <h2 class="pacha-info__tagline">
            4 Magical Days in An Eco Spirit Community
          </h2>
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={buyTickets}
            href="https://pachabuela-kulam-inanitah-fire-season-gathering.eventbrite.com"
            class="button is-big mt-5"
          >
            Get tickets
          </a>
        </div>
      </Hero>

      {frontpage.showVideo && (
        <div class="container is-padded is-small mt-5">
          <Video code={frontpage.youtubeId}></Video>
        </div>
      )}

      {frontpage.blocks.map(block => {
        if (block.__typename === "DatoCmsText") {
          return (
            <div key={block.id} class="mb-3">
              <LazyLoad offset={100}>
                <Text
                  heading={block.heading}
                  subheading={block.subheading}
                  body={block.body}
                ></Text>
              </LazyLoad>
            </div>
          )
        }
        return null
      })}

      <div class="container mb-5 is-text-center is-padded">
        <h1 class="h1 mt-5 mb-5">Lineup</h1>
        {sortedFacilitators.map(facilitator => {
          return (
            <LazyLoad key={facilitator.id} offset={100}>
              <Link
                to={`/${facilitator.url}`}
                style={{
                  backgroundImage: `url(${facilitator.mainImage.url}?w=1200&q=27&auto=compress)`,
                }}
                class="overlay-card is-clickable mb-1"
              >
                <h1 class="heading">{facilitator.heading}</h1>
              </Link>
            </LazyLoad>
          )
        })}
      </div>

      <div class="container mb-5 is-text-center is-padded">
        <h1 class="h1 mt-5 mb-5">Offerings</h1>
        <div class="grid is-3">
          {sortedSessions.map(session => {
            return (
              <LazyLoad key={session.id} offset={100}>
                <div
                  style={{
                    backgroundImage: `url(${session.mainImage.url}?w=1200&q=20&auto=compress)`,
                  }}
                  class="overlay-card mb-1"
                >
                  <h3 class="h3">{session.heading}</h3>
                </div>
              </LazyLoad>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    datoCmsFrontpage {
      showVideo
      youtubeId
      mainImage {
        url
      }
      blocks {
        ... on DatoCmsText {
          id
          heading
          subheading
          body
          __typename
        }
      }
    }
    allDatoCmsFacilitator {
      nodes {
        id
        url
        heading
        mainImage {
          url
        }
        priority
        columnSize
      }
    }
    allDatoCmsSession {
      nodes {
        readMoreLink
        heading
        id
        showReadMore
        subheading
        mainImage {
          url
        }
        priority
      }
    }
  }
`
