import React from "react"

export default function Video({ code }) {
  return (
    <div class="container is-padded is-small mt-5">
      <div class="embed-container">
        <iframe
          title="Video"
          src={`https://www.youtube.com/embed/${code}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}
