import React from "react"
import "./hero.css"

export default function Hero({ src, size = "normal", children }) {
  return (
    <div
      class={`hero is-${size}`}
      style={{
        backgroundImage: `url(${src}?q=27)`,
      }}
    >
      <div class="hero__content">{children}</div>
    </div>
  )
}
